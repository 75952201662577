<template>
  <div class="infobox">
    <div class="h1s">
      <h1>- 《华夏云课堂第三方信息共享清单》 -</h1>
    </div>

    <div class="titles">
      <p class="textinfo">
        为保障华夏云课堂App相关功能的实现与应用安全稳定的运行，我们可能会接入由第三方提供的软件开发包（SDK）实现相关目的。<br /><br />
        我们会对合作方获取信息的软件工具开发包（SDK）进行严格的安全监测，以保护数据安全。<br /><br />
        我们接入的第三方SDK目录如下：<br /><br />
        <span class="span1">腾讯云服务浏览</span><br /><br />
        公司名称：<span class="span1">腾讯云计算（北京）有限责任公司</span><br /><br />
        使用目的：优化用户浏览网页的体验
        <br /><br /> 收集信息类型：设备信息<br /><br />
        第三方隐私政策或官网链接：<a href="https://x5.tencent.com/docs/index.html">https://x5.tencent.com/docs/index.html</a> <br /><br />
        <span class="span1">腾讯播放器</span><br /><br />
        公司名称：<span class="span1">腾讯云计算（北京）有限责任公司</span><br /><br />
        使用目的：为用户观看视频的服务
        <br /><br /> 收集信息类型：设备信息<br /><br />
        第三方隐私政策或官网链接：<a href="https://cloud.tencent.com/document/product/881/20213">https://cloud.tencent.com/document/product/881/20213</a><br /><br />
        <span class="span1">号码认证</span> <br /><br />
        公司名称：<span class="span1">腾讯云计算（北京）有限责任公司</span><br /><br />
        使用目的：提供一键登录、本机校验两大功能<br /><br />
        收集信息类型：设备信息、设备品牌、运营商信息、网络信息、应用信息（应用名、应用版本号）<br /><br />
        第三方隐私政策或官网链接：<a href="https://cloud.tencent.com/product/nvs">https://cloud.tencent.com/product/nvs</a><br /><br />
        <span class="span1">微信支付</span><br /><br />
        公司名称：<span class="span1">腾讯云计算（北京）有限责任公司</span><br /><br />
        使用目的：提供第三方支付<br /><br />
        收集信息类型：设备信息（IMEI/IMSI/MAC地址/AndroidID）、网络信息、微信账号信息<br /><br />
        第三方隐私政策或官网链接：<a href="https://developers.weixin.qq.com/doc/oplatform/Mobile_App/WeChat_Pay/Vendor_Service_Center.html">https://developers.weixin.qq.com/doc/oplatform/Mobile_App/WeChat_Pay/Vendor_Service_Center.html</a><br /><br />
        <span class="span1">支付宝支付</span><br /><br />
        公司名称：<span class="span1">支付宝（中国）网络技术有限公司</span><br /><br />
        使用目的：提供第三方支付
        <br /><br />收集信息类型：设备信息（IMEI/IMSI/AndroidID/MAC地址/BSSID/硬件序列号/ICCID）、定位信息、网络类型、设备型号、设备品牌、运营商信息、传感器列表、应用信息<br /><br />
        第三方隐私政策或官网链接：<a href="https://opendocs.alipay.com/open/204/105051?ref=api">https://opendocs.alipay.com/open/204/105051?ref=api</a><br /><br />
        <span class="span1">微信登录</span><br /><br />
        公司名称：<span class="span1">腾讯云计算（北京）有限责任公司</span><br /><br />
        使用目的：提供第三方登录<br /><br />
        收集信息类型：设备信息（IMEI/IMSI/MAC地址/AndroidID）、网络信息、微信账号信息<br /><br />
        第三方隐私政策或官网链接：<a href="https://developers.weixin.qq.com/doc/oplatform/Mobile_App/WeChat_Login/Development_Guide.html">https://developers.weixin.qq.com/doc/oplatform/Mobile_App/WeChat_Login/Development_Guide.html</a><br /><br />
        <span class="span1">友盟</span><br /><br />
        公司名称：<span class="span1">友盟同欣（北京）科技有限公司</span><br /><br />
        使用目的：帮助用户江信息分享至第三方应用<br /><br />
        收集信息类型：设备信息（IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/SIM卡IMSI/IP/地理位置）、获取设备序列号、获取android_id、传感器信息<br /><br />
        第三方隐私政策或官网链接：<a href="https://developer.umeng.com/docs/128606/cate/128606">https://developer.umeng.com/docs/128606/cate/128606</a><br /><br />

         <span class="span1">微信分享</span><br /><br />
        公司名称：<span class="span1">腾讯云计算（北京）有限责任公司</span><br /><br />
        使用目的：提供第三方分享<br /><br />
        收集信息类型：设备信息（IMEI/IMSI/AndroidID/MAC地址/BSSID/硬件序列号/ICCID）、网络信息、获取设备序列号、获取android_id、传感器信息<br /><br />
        第三方隐私政策或官网链接：<a href="https://developers.weixin.qq.com/doc/oplatform/Mobile_App/Share_and_Favorites/Android.html">https://developers.weixin.qq.com/doc/oplatform/Mobile_App/Share_and_Favorites/Android.html</a><br /><br />
        
        <span class="span1">QQ分享</span><br /><br />
        公司名称：<span class="span1">腾讯云计算（北京）有限责任公司</span><br /><br />
        使用目的：提供第三方分享<br /><br />
        收集信息类型：设备信息（IMEI/IMSI/AndroidID/MAC地址/BSSID/硬件序列号/ICCID）、网络信息、获取设备序列号、获取android_id、传感器信息<br /><br />
        第三方隐私政策或官网链接：<a href="https://connect.qq.com/index.html">https://connect.qq.com/index.html</a><br /><br />

        <span class="span1">新浪分享</span><br /><br />
        公司名称：<span class="span1">北京微梦创科网络技术有限公司</span><br /><br />
        使用目的：提供第三方分享<br /><br />
        收集信息类型：设备信息（IMEI/IMSI/AndroidID/MAC地址/BSSID/硬件序列号/ICCID）、网络信息、获取设备序列号、获取android_id、传感器信息<br /><br />
        第三方隐私政策或官网链接：<a href="https://open.weibo.com/">https://open.weibo.com/</a><br /><br />
        
      
      </p>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style lang="less" scoped>
.infobox {
  width: 1200px;
  margin: 70px auto;
  overflow: hidden;
  background: #ffffff;
  padding: 48px 40px;
  // height: 800px;
}
.h1s {
  text-align: center;
  line-height: 22px;
  font-family: PingFangMedium;
  font-size: 30px;
  h1 {
    font-weight: 600;
  }
}
.span1 {
  font-size: 16px;
  font-family: PingFangMedium;
  font-weight: 600;
  color: #333333;
}
.textinfo {
  font-size: 16px;
  font-family: PingFangMedium;
  color: #333333;
  line-height: 24px;
}
.h2s {
  font-size: 24px;
  font-family: Source Han Sans CN-Bold;
  font-weight: bold;
  color: #333333;
}
.titles {
  margin-top: 36px;
  padding: 0 20px;
}

.righttxt {
  text-align: right;
  line-height: 18px;
  display: block;
  font-family: PingFang SC;
  font-size: 12px;
}
</style>